<template>
    <div>
        <page-header
            :title="capitalize(selectedPaymentSource.accountType)"
            @onClose="$router.go(-1)"
            show-back
        />
        <div class="container mt-2">
            <list-row
                :title="$t('pages.PaymentSources.Type')"
                :value="capitalize(selectedPaymentSource.accountType)"
                value-class="text-muted"
                contained
            />
            <list-row
                :title="$t('pages.PaymentSources.Account')"
                :value="'Ending in ' + last4Chars(selectedPaymentSource.maskedAccountNumber)"
                value-class="text-muted"
                contained
            />
            <base-button
                class="btn-secondary mt-2"
                @click="modalVisible = true"
            >
                {{ $t('pages.PaymentSources.RemoveSource') }}
            </base-button>
        </div>
        <modal
            :show="modalVisible"
            title="Are you sure?"
            @close="modalVisible = false"
        >
            <p class="mb-3 text-center">
                {{ $t('pages.PaymentSources.ConfirmRemoveText') }}
            </p>
            <base-button
                class="mt-3"
                @click="handleDeleteSource"
            >
                {{ $t('pages.PaymentSources.ConfirmRemoveCta') }}
            </base-button>
        </modal>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import PageHeader from '@/components/PageHeader'
    import ListRow from '@/components/ListRow'
    import BaseButton from '@/components/base/BaseButton'
    import Modal from '@/components/Modal'
    import { logger } from '@/utils/logger'
    import format from '@/mixins/format'

    export default {
        name: 'PaymentSourceDetail',
        components: { Modal, BaseButton, ListRow, PageHeader },
        mixins: [format],
        data: function () {
            return {
                modalVisible: false,
            }
        },
        mounted: function () {
            if (!Object.keys(this.selectedPaymentSource).length) {
                this.$router.replace('paymentSource')
            } else {
                this.$logEvent('view_payment_source_detail')
            }
        },
        computed: {
            ...mapState(['selectedPaymentSource']),
        },
        methods: {
            ...mapActions(['deletePaymentSource']),
            handleDeleteSource: async function () {
                try {
                    await this.deletePaymentSource(this.selectedPaymentSource.token)
                    this.modalVisible = false
                    this.$logEvent('click_submit_delete_funding_source')
                    logger.info('successfully deleted funding source')
                    await this.$router.replace('paymentSource')
                } catch (e) {
                    logger.info('problem deleting funding source', e)
                }
            },
        },
    }
</script>

<style scoped></style>
